import { useState, useEffect, createRef } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import gql from 'graphql-tag';
import CircularProgress from '@mui/material/CircularProgress';

import { LangConveter as LC } from '../lib/gettext';
import { ScrollToTopOnce } from '../lib/components';
import { IMG } from '../lib/imgLib';
import __ from '../lib/gettext';
import './css/home.css';

export const Loading = () => (
  <h1 className='container section flex-grow text-center mt-4'>
    {__('Loading')}...
    <CircularProgress />
  </h1>
);

const Team = () => {
  const FansiAngels = [
    {
      avatar: 'https://fansi-static.s3.ap-southeast-1.amazonaws.com/Home/Image/Angel_1.jpg',
      name: '陳勇志',
      title: 'Angel',
      subTitle: '相信音樂創辦人\nB’in Music co-founder',
      twLink: '',
      fbLink: '',
      inLink: '',
    },
    {
      avatar: 'https://fansi-static.s3.ap-southeast-1.amazonaws.com/Home/Image/Angel_2.jpg',
      name: 'Negi',
      title: 'Advisor',
      subTitle: 'BAYC #5417 & Council Member',
      twLink: 'https://twitter.com/negithenagi',
      fbLink: '',
      inLink: '',
    },
    {
      avatar: 'https://fansi-static.s3.ap-southeast-1.amazonaws.com/Home/Image/Angel_3.jpg',
      name: 'TK Chen',
      title: 'Founder',
      subTitle: 'CEO of FANSI',
      twLink: 'https://twitter.com/TaiKuChen',
      fbLink: 'https://www.facebook.com/taiku.chen  ',
      inLink: 'https://www.linkedin.com/in/taikuchen/',
   },
]

    return (
    <div className="w-full mt-6 lg:mt-48" style={{ backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 20%), url(https://fansi-static.s3.ap-southeast-1.amazonaws.com/Home/Banner/portal-section-5-bg.png)`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
      <div className='section m-auto mt-32 lg:mt-0'>
        <p className="z-20 RussoOne MetaBoom text-28p lg:text-5xl text-center pt-10 lg:pt-20">Team & Angles</p>
          <div style={{ paddingBottom: '30px', position: 'relative' }} >
            <Carousel
              draggable
              arrows={false}
              partialVisible
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024
                  },
                  items: 3,
                  slidesToSlide: 3
                },
                tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: 1,
                  slidesToSlide: 1 // optional, default to 1.
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0
                  },
                  items: 1,
                  // slidesToSlide: 1,
                  partialVisibilityGutter: 90,
                }
  
              }}
              // showDots
              >
              {Object.keys(FansiAngels).map((x: any) => {
                let member = FansiAngels[x];
                let cls = 'mx-4 ';
                return (
                  <div key={'team' + x} className={cls + ' flex mb-4'}>
                    <AngelBlock {...member} />
                  </div>
                )
              })}
            </Carousel>
        </div>
      </div>
    </div>)
  }
  
const AngelBlock = ({ name, title, subTitle, twLink, fbLink, avatar, inLink }:
    { name: string, title: string, subTitle: string, twLink?: string, fbLink?: string, avatar?: string, inLink?: string }) => {
    return (
      <div className='relative mx-auto'>
        <div className='relative rounded-lg w-60 lg:w-72 h-[280px] lg:h-[309px] mt-18 p-6'
          style={{ backgroundColor: 'rgba(30, 57, 65, 0.74)' }}>
          <div className='mx-auto  w-[106px] lg:w-[123px] aspect-square rounded-full overflow-hidden' style={{ boxShadow: '0 6px 7px 0 rgba(0, 0, 0, 0.25)' }}>
            <img src={avatar} className='w-full h-full object-cover' alt='avatar' />
          </div>
          <div className='w-full mt-6 pb-6'>
          <p className='w-full text-center Roboto text-sm lg:text-base text-white'>{title}</p>
          <p className='w-full text-center Roboto text-xl font-extrabold' >{name}</p>            
          <div className='h-9' style={{color: '#cbcbcb'}}> 
            <p className='w-full text-center Roboto text-xs font-medium whitespace-pre' >{subTitle}</p>            
          </div>
            <div className='justify-center mt-2 flex'>
              {twLink ? <a href={twLink} style={{ zIndex: 99 }} target="_blank" rel="noreferrer noopener" >
                <IMG className='mx-2' style={{ width: 21, height: 21 }} src='Twitter' />
              </a> : ''}
              {fbLink ? <a href={fbLink} style={{ zIndex: 99 }} target="_blank" rel="noreferrer noopener" >
                <IMG className='mx-2' style={{ width: 22, height: 22 }} src='FBWhite' />
              </a> : ''}
              {inLink ? <a href={inLink} style={{ zIndex: 99 }} target="_blank" rel="noreferrer noopener" >
                <IMG className='mx-2' style={{ width: 20, height: 20 }} src='Linkedin' />
              </a> : ''}
            </div>
          </div>
        </div>
      </div >
    )
  }

const FeatureCampaigns = () => {
  const GET_News = gql`
    query getRecentNews {
      eventList: getRecentNews(status:0, type:3, show:true){
        count
          list{
          id
          title
          newsDate
          media
          imgUrl
          videoUrl
          externalUrl
          status
          show
          type
        }
      }
  }`;
  
  const NftList = useQuery(GET_News, { fetchPolicy: 'network-only' });
  if (NftList.loading) return <Loading />
  const eventList: any[] = NftList.data ? NftList.data.eventList.list : null;

    function CustomRightArrow({ onClick }: any) {
      function handleClick() {
        onClick();
      }
  
      return (
        <div className="absolute right-0 top-32 cursor-pointer" onClick={handleClick}>
          <IMG className="w-8 h-8 lg:w-12 h-12" src='RoundCArrowRight' />
        </div>
      );
    }
  
    function CustomLeftArrow({ onClick }: any) {
      function handleClick() {
        onClick();
      }
  
      return (
        <div className="absolute top-32 cursor-pointer" onClick={handleClick}>
          <IMG className="w-8 h-8 lg:w-12 h-12" src='RoundCArrowLeft' />
        </div>
      );
    }
  
    const CustomDot = ({ onMove, index, onClick, active }: any) => {
      return (
        <div
          className={(active ? 'active mr-2 mb-6 rounded-full' : 'inactive mr-2 mb-6 rounded-full') + ' '}
          style={active ? selectedTeamDot : unselectedTeamDot}
          onClick={() => onClick()}
        ></div>
      );
    };
  
    return (
    <div className='section w-full m-auto'>
        <p className="z-20 RussoOne MetaBoom text-28p lg:text-5xl text-center pt-10 lg:pt-20">Featured Campaigns</p>
          <div className='mt-10 mb-12' style={{ paddingBottom: '30px', position: 'relative' }} >
            <Carousel
              arrows
              draggable
              infinite
              customLeftArrow={<CustomLeftArrow />}
              customRightArrow={<CustomRightArrow />}
              customDot={<CustomDot />}
              renderDotsOutside
              removeArrowOnDeviceType={["tablet", "mobile"]}
              partialVisible
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024
                  },
                  items: 3,
                  slidesToSlide: 1,
                  partialVisibilityGutter: 0,
                },
                tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: 1,
                  slidesToSlide: 1 // optional, default to 1.
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0
                  },
                  items: 1,
                  slidesToSlide: 1,
                  partialVisibilityGutter: 60,
                }
  
              }}
              showDots
              >
              {Object.keys(eventList).map((x: any) => {
                let member = eventList[x];
                let cls = 'mx-4 ';
                return (
                  <div key={'feature' + x} className={cls + ' flex mb-4 justify-center '}>
                    <ArticleCard {...member} />
                  </div>
                )
              })}
            </Carousel>
        </div>
    </div>)
  }
  
type ArticleCardProps = {
  id?: number;
  title: string;
  newsDate: string;
  media: string;
  imgUrl: string;
  videoUrl?: string;
  externalUrl: string;
  status?: number;
  type?: number;
  show?: boolean;
}
  
const ArticleCard = ({ title, newsDate, media, imgUrl, externalUrl, type }: ArticleCardProps) => {
  return (
    <a className='no-underline' target='_blank' href={externalUrl} rel="noreferrer noopener">
          < div className='w-[299px] h-60'>
        <img className='w-[299px] h-[137px] object-cover' src={imgUrl} style={{ borderRadius: 16 }} alt='banner' />
            <p className='Roboto text-xs'>{newsDate.replace('-', '/').replace('-', '/')}</p>
            <p className='Roboto text-lg font-semibold' style={{color:'#5fddff'}}>{title}</p>
        <p className='Roboto text-xs' style={{ color: '#c5c5c5' }}>{LC.getLocalText(media)}</p>
          </div>
      </a>
  )
}

const Home = () => {
    const [hideArrow, setArrow] = useState(false);
    let divRef: React.RefObject<HTMLDivElement>;
    divRef = createRef();

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setArrow(entry.isIntersecting)
        }
        );
        if (divRef.current) observer.observe(divRef.current);
        return () => {
            observer.disconnect();
        };
    }, [divRef]);

    return (
        <div className='w-full h-full relative flex'>
            <ScrollToTopOnce />            
            <div className='w-full relative'>
                {hideArrow ? '' : <div className='fixed bottom-0 pb-12 lg:pb-8 w-full z-50'>
                    <div className='w-full h-10 relative flex justif-center'>
                        <div className='w-8 h-8 arrow arrow-first' />
                    </div>
                </div>}
                <div className='w-full pt-14 relative' aria-label='Portal-Fansi-Go-Banner'>
                  <img className='w-full' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/Home/Banner/portal-fansi-go-banner.png' alt='banner-fansi-go' />
                  <a className='my-auto mr-7' href={'https://go.fansi.me/'} target="_blank" rel="noreferrer noopener">
                    <div className='w-full absolute-center text-center text-white font-medium text-xs lg:text-[23px] lg:pt-80 pt-36'>
                        <p className='whitespace-pre'>{__('Portal_Fansi_Go_Banner')}</p>
                        <div className='mx-auto flex rounded-full bg-[#fb7f65] justify-center lg:40 w-1/3 lg:w-1/4 mt-3 lg:mt-12 p-1'>
                          <p className='my-auto'>{__('Home_Redirect')}</p>
                          <IMG className='mt-0 lg:mt-1 lg:w-6 w-4' src='ArrowRight' />
                          </div>
                    </div>
                  </a>
                </div>
                <div className='w-full relative' arial-lable='Portal-Home-Sec-1'>
                    <img className='hidden lg:block absolute object-cover top-0 left-0 w-full h-full z-0' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/Home/Banner/portal-section-1-bg.jpg' alt='portal-sec-1-bg' />
                    <img className='block lg:hidden absolute object-cover top-0 left-0 w-full h-full z-0' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/Home/Banner/portal-section-1-bg-m.jpg' alt='portal-sec-1-bg-m' />
                    <div className='absolute w-full h-full' style={{ backgroundImage: 'linear-gradient(0deg,#050505,transparent 16%)' }} />
                    <div className='relative hidden lg:flex w-full section-lg'>
                        <div className='w-full h-0' style={{ paddingBottom: '58%' }} />
                        <img className='absolute' style={{ width: '31.88%', left: '5%', top: '33.77%' }} src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/Home/Banner/portal-banner-wing-left.png' alt='banner-wing-left' />
                        <img className='absolute' style={{ width: '31.88%', right: '4.65%', top: '33.77%' }} src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/Home/Banner/portal-banner-wing-right.png' alt='banner-wing-right' />
                    </div>
                    <div className='relative flex lg:hidden w-full section-lg'>
                        <div className='w-full h-0' style={{ paddingBottom: '174.87%' }} />
                        <img className='absolute w-full' style={{ top: '50%' }} src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/Home/Banner/portal-banner-wing-mobile.png' alt='banner-wing-mobile' />
                    </div>
                    <div className='absolute top-0 left-0 w-full h-full z-30'>
                        <div className='relative section-lg w-full h-full pt-44 lg:pt-54 ' >
                            <p className='RussoOne MetaBoom w-full text-[34px] lg:text-6xl text-center whitespace-pre'>{__('Home_Sec_1_Title')}</p>
                            <p className='mt-2 RussoOne MetaBoom w-full text-xl lg:text-[42px] text-center whitespace-pre' style={{ lineHeight: 1.1 }}>{__('Home_Sec_1_subtitle')}</p>
                            <div className='w-full flex h-10 mt-9 lg:mt-12 justify-center'>
                                <a className='my-auto mr-7' href={__('DC_INVITE_LINK')} target="_blank" rel="noreferrer noopener">
                                    <IMG className='w-9 lg:w-12 h-9 lg:h-12' src='DiscordB' />
                                </a>
                                <a className='my-auto mr-7' href={__('FANSI_Twitter')} target="_blank" rel="noreferrer noopener">
                                    <IMG className='w-9 lg:w-12 h-9 lg:h-12' src='TwitterB' />
                                </a>
                                <a className='my-auto' href={__('FANSI_LinkTree')} target="_blank" rel="noreferrer noopener">
                                    <IMG className='w-9 lg:w-12 h-9 lg:h-12' src='LinkTreeB' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='w-full relative' arial-lable='Portal-Home-Sec-2'>
                    <img className='hidden lg:block absolute object-cover top-0 left-0 w-full h-full z-0' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/Home/Banner/portal-section-2-bg.jpg' alt='portal-sec-2-bg' />
                    <img className='block lg:hidden absolute top-0 left-0 w-full h-full z-0' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/Home/Banner/portal-section-2-bg-m.jpg' alt='portal-sec-2-bg-m' />
                    <div className='absolute w-full h-full' style={{ backgroundImage: 'linear-gradient(0deg,#050505,transparent 16%)' }} />
                    <div className='hidden lg:flex w-full section-lg'>
                        <div className='w-full h-0' style={{ paddingBottom: '56.53%' }} />
                    </div>
                    <div className='lex lg:hidden w-full section-lg  '>
                        <div className='w-full h-0' style={{ paddingBottom: '194.33%' }} />
                    </div>
                    <div className='absolute top-0 left-0 w-full h-full z-30'>
                        <div className='relative section-lg w-full h-full pt-16 lg:pt-22 ' >
                            <IMG src='MetaBoom' className='w-48 lg:w-92 mx-auto' />
                            <p className='RussoOne MetaBoom w-full mt-9 lg:mt-0 leading-normal text-2xl lg:text-[50px] text-center whitespace-pre'>{__('Home_Sec_2_Title')}</p>
                            <div className='w-full flex flex-wrap mt-10 lg:mt-12 justify-center'>
                                <div className='w-full lg:w-76 mr-0 lg:mr-8 px-5 lg:px-0 flex lg:block'>
                                    <img className='mr-3 lg:mx-auto w-30 lg:w-64' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/Home/Image/metaboom-feature-img-1.png' alt='MB-feature-1' />
                                    <p className='Roboto text-lg lg:text-xl text-left lg:text-center whitespace-normal lg:whitespace-pre my-auto'>{__('Home_Sec_2_Info1')}</p>
                                </div>
                                <div className='w-full lg:w-76 mr-0 lg:mr-8 px-5 lg:px-0 flex lg:block'>
                                    <img className='mr-3 lg:mx-auto w-30 lg:w-64' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/Home/Image/metaboom-feature-img-2.png' alt='MB-feature-2' />
                                    <p className='Roboto text-lg lg:text-xl text-left lg:text-center whitespace-normal lg:whitespace-pre my-auto'>{__('Home_Sec_2_Info2')}</p>
                                </div>
                                <div className='w-full lg:w-76 px-5 lg:px-0 flex lg:block'>
                                    <img className='mr-3 lg:mx-auto w-30 lg:w-64' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/Home/Image/metaboom-feature-img-3.png' alt='MB-feature-3' />
                                    <p className='Roboto text-lg lg:text-xl text-left lg:text-center whitespace-normal lg:whitespace-pre my-auto'>{__('Home_Sec_2_Info3')}</p>
                                </div>
                            </div>
                <Link to={__('MetaBoom_URL')} target='_blank' rel="noreferrer noopener">
                                <div className='mt-10 lg:mt-12 mx-auto bg-gen2blue rounded-lg w-48 lg:w-56 h-11 lg:h-14 flex justify-center'>
                                    <p className='NotoSans font-medium text-black text-lg lg:text-22p my-auto mr-2'>{__('Home_Start')}</p>
                                    <IMG src='ArrowRightBa' className='w-6 lg:w-7 h-6 lg:h-7 my-auto' />
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className='w-full relative' arial-lable='Portal-Home-Sec-3'>
                    <img className='hidden lg:block absolute object-cover top-0 left-0 w-full h-full z-0' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/Home/Banner/portal-section-3-bg.jpg' alt='portal-sec-3-bg' />
                    <img className='block lg:hidden absolute object-cover top-0 left-0 w-full h-full z-0' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/Home/Banner/portal-section-3-bg-m.jpg' alt='portal-sec-3-bg-m' />
                    <div className='absolute w-full h-full' style={{ backgroundImage: 'linear-gradient(#050505,transparent 16%),linear-gradient(0deg,#050505,transparent 16%)' }} />
                    <div className='hidden lg:flex w-full section-lg'>
                        <div className='w-full h-0' style={{ paddingBottom: '48.47%' }} />
                    </div>
                    <div className='lex lg:hidden w-full section-lg  '>
                        <div className='w-full h-0' style={{ paddingBottom: '226.74%' }} />
                    </div>
                    <div className='absolute top-0 left-0 w-full h-full z-30'>
                        <div className='relative section-lg w-full h-full pt-14 lg:pt-36 ' >
                            <div className='w-full lg:w-2/3 mx-auto'>
                                <div className='mx-auto lg:ml-0 w-76 lg:w-7/12 pr-0 lg:pr-8'>
                                    <IMG src='RisingStars' className='w-full' />
                                </div>
                                <p className='Roboto font-medium italic text-center lg:text-left text-xl lg:text-[37px] mt-1 mb-96 lg:mb-0'>{__('Home_Sec_3_subtitle')}</p>
                                <div className='pt-4 lg:pt-12'>
                                    <div className='flex px-10 lg:px-0'>
                                        <IMG src='Star' className='w-9 h-9 mr-2 my-auto' />
                                        <p className='Roboto text-lg lg:text-2xl font-medium lg:font-extrabold'>{__('Home_Sec_3_Info1')}</p>
                                    </div>
                                    <div className='flex px-10 lg:px-0 mt-2'>
                                        <IMG src='Star' className='w-9 h-9 mr-2 my-auto' />
                                        <p className='Roboto text-lg lg:text-2xl font-medium lg:font-extrabold'>{__('Home_Sec_3_Info2')}</p>
                                    </div>
                                    <div className='flex px-10 lg:px-0 mt-2'>
                                        <IMG src='Star' className='w-9 h-9 mr-2 my-auto' />
                                        <p className='Roboto text-lg lg:text-2xl font-medium lg:font-extrabold'>{__('Home_Sec_3_Info3')}</p>
                                    </div>
                                </div>
                  <Link to={__('RisingStars_URL')} target='_blank' rel="noreferrer noopener">
                                    <div className='mt-9 lg:mt-18 rounded-lg w-36 lg:w-68 h-12 lg:h-14 flex justify-center mx-auto lg:ml-0' style={{ backgroundImage: 'radial-gradient(circle at 0% 15%, #fff1a9, #00e4d7 45%, #ff554d 98%)' }}>
                                        <p className='NotoSans font-medium text-black text-lg lg:text-22p my-auto mr-1 lg:mr-2'>{__('Home_Start')}</p>
                                        <IMG src='ArrowRightBa' className='w-6 lg:w-7 my-auto' />
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='section-lg w-full relative pt-0 lg:pt-7' arial-lable='Portal-Home-Sec-6'>
                    <FeatureCampaigns />
                </div>
                <div className='section-lg w-full relative pt-0 pt-14 h-40 lg:h-52' arial-lable='Portal-Home-Sec-4'>
                    <p className='RussoOne MetaBoom w-full text-28p lg:text-5xl text-center -mt-12 lg:mt-0'>{__('Home_Sec_4_Title')}</p>
                    <div className='relative overflow-hidden w-full h-44 flex mt-7 lg:mt-10'>
                        <div className='w-12 lg:w-40 absolute left-0 h-full z-20' style={{ zIndex: 61, backgroundImage: 'linear-gradient(to left, rgba(0, 0, 0, 0), rgb(0, 0, 0,100))' }} />
                        <div className='absolute flex flex-no-wrap' style={{ width: 1412, zIndex: 60, height:90 }} >
                            <img className='marqueeLeft mr-12' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/Home/Banner/banner-partners-1.png' alt='banner-partner' />
                            <img className='marqueeLeft' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/Home/Banner/banner-partners-1.png' alt='banner-partner' />
                        </div>
                        <div className='absolute flex flex-no-wrap' style={{ width: 1412, zIndex: 60, top:'104px', height: 73 }} >
                            <img className='marqueeRight mr-12' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/Home/Banner/banner-partners-2.png' alt='banner-partner' />
                            <img className='marqueeRight' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/Home/Banner/banner-partners-2.png' alt='banner-partner' />
                        </div>
                        <div className='w-12 lg:w-40 absolute right-0 h-full z-20' style={{ zIndex: 61, backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 0), rgb(0, 0, 0,100))' }} />
                    </div>
                </div>

                {/* <div className='section-lg w-full relative pt-0 lg:pt-7' arial-lable='Portal-Home-Sec-MB'>
                    <MetaBoomBanner />
                </div> */}
                <div className='section-lg w-full relative pt-0 lg:pt-7' arial-lable='Portal-Home-Sec-5'>
                    <Team />
                </div>
              
                <div className='w-full h-px pb-40' ref={divRef} arial-lable='page-end' />
            </div>
        </div>
    )
}


const selectedTeamDot = {
  width: 9,
  height: 9,
  flexGrow: 0,
  margin: '0 24px 0 0',
  backgroundColor: '#5fddff',
};

const unselectedTeamDot = {
  width: 9,
  height: 9,
  flexGrow: 0,
  margin: '0 24px 0 0',
  backgroundColor: '#d9d9d9',
};

export default Home;