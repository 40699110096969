export const zh = {
  DC_INVITE_LINK: 'https://discord.gg/vtkV88bN2E',
  FANSI_IG: 'https://www.instagram.com/fansi_nft',
  FANSI_Twitter: 'https://twitter.com/metaboom_nft',
  FANSI_LinkTree: 'https://linktr.ee/fansi_nft',
  FANSI_OpenSea: 'https://opensea.io/collection/metaboom-nft',
  MetaBoom_URL: 'https://metaboom.fansi.me',
  RisingStars_URL: 'https://metaboom.fansi.me/hatching',
  APP_Google: 'https://play.google.com/store/apps/details?id=me.fansi.app',
  APP_Apple: 'https://apps.apple.com/tw/app/metaboom/id6444411102',
  About: '關於',
  Lang_Zh: '繁中',
  Lang_En: 'EN',
  Google_Login: 'Google Login',
  Facebook_Login: 'Facebook Login',
  Terms_of_Service: '服務條款',
  Privacy_Policy: '隱私權條款',
  Home_Desc: "FANSI - Web3 Experimental Music Platform from Asia",
  Web_Default_Desc:
    'Revolutionizing Asian Music with Web3: Digital Ticketing, Indie Releases and Blockchain Certificates.',
  Home_Sec_1_Title: '不只是收藏品',
  Home_Sec_1_subtitle: '讓你的音樂NFT為你產生收益',
  Home_Sec_2_Title: '加入這場音樂聖戰，\n享受更好的流通性',
  Home_Sec_2_Info1: '體驗並發掘更多Web3好音樂',
  Home_Sec_2_Info2: '更完善的音樂產業生態系',
  Home_Sec_2_Info3: '隨時聆聽並賺取收益！',
  Home_Start: '立馬開始',
  Home_Sec_3_subtitle: '最佳Web3音樂人發行方案',
  Home_Sec_3_Info1: 'Web3新興音樂創作者的堅強後盾',
  Home_Sec_3_Info2: '發掘最有價值的Web3音樂',
  Home_Sec_3_Info3: '蒐藏獨家潛力web3音樂',
  Home_Sec_4_Title: '投資方與合作夥伴',
  Portal_Fansi_Go_Banner: 'Web3 票券系統\n專為表演者和粉絲打造',
  Home_Redirect: '立馬開始',
};

// Placeholder english dictionary.
export const en = {
  ...zh,
  About: 'About',
  Google_Login: 'Google Login',
  Facebook_Login: 'Facebook Login',
  Terms_of_Service: 'Terms of Service',
  Privacy_Policy: 'Privacy Policy',
  Home_Sec_1_Title: 'Beyond collectibles',
  Home_Sec_1_subtitle: 'Unleash the earning power\n of your music NFTs',
  Home_Sec_2_Title: 'Enjoy enhanced liquidity by\n participating the crusade.',
  Home_Sec_2_Info1: 'Explore and discover New\n web3 music.',
  Home_Sec_2_Info2: 'A more complete music\n ecosystem',
  Home_Sec_2_Info3: 'Listen and Earn\n on the Go !',
  Home_Start: 'Get Started',
  Home_Sec_3_subtitle: 'The Best Artist NFT drops',
  Home_Sec_3_Info1: 'Support emerging web3 artists',
  Home_Sec_3_Info2: 'Discover the best Web3 music',
  Home_Sec_3_Info3: 'Collect exclusive audiovisual artworks',
  Home_Sec_4_Title: 'Investors & Partners',
  Portal_Fansi_Go_Banner: 'A Web3 ticketing\ndesigned for artist and fans.',
  Home_Redirect: 'Start',
};
