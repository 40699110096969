import AppleStore from '../components/img/img-apple-store.svg';
import ArrowDown from '../components/img/icon-arrow-down.svg';
import ArrowRight from '../components/img/icon-arrow-right.svg';
import ArrowRightBa from '../components/img/icon-arrow-right-black.svg';
import ArrowUp from '../components/img/icon-arrow-up.svg';
import ArrowUpB from '../components/img/icon-arrow-up-blue.svg';
import Clock from '../components/img/icon-clock-white.svg';
import Close from '../components/img/btn-bgc-close.svg';
import CloseOrange from '../components/img/btn-bgc-close-orange.svg';
import Discord from '../components/img/icon-discord.svg'
import DiscordB from '../components/img/icon-discord-blue.svg'
import ExclamationBlack from '../components/img/icon-exclamation-black.svg';
import FANSI from '../components/img/icon-fansi.svg'
import FB from '../components/img/icon-facebook.svg';
import FBWhite from '../components/img/icon-facebook-white.svg';
import Google from '../components/img/icon-google.svg';
import GooglePlay from '../components/img/img-google-play.svg';
import IG from '../components/img/icon-instagram.svg';
import Info from '../components/img/icon-info.svg';
import InfoWhite from '../components/img/icon-info-white.svg';
import InfoBlue from '../components/img/icon-info-blue.svg';
import Linkedin from '../components/img/icon-linkedin.svg';
import LinkTree from '../components/img/icon-link-tree.svg';
import LinkTreeB from '../components/img/icon-link-tree-blue.svg';
import Logout from '../components/img/icon-logout.svg';
import Logo from '../components/img/FANSI_MB_logo.svg';
import LogoFull from '../components/img/icon-fansi-logo-full.svg';
import MM from '../components/img/icon-metamask.png';
import Menu from '../components/img/icon-menu.svg';
import Member from '../components/img/icon-member-white.svg';
import MetaBoom from '../components/img/icon-MetaBoom.svg';
import OpenSea from '../components/img/icon-opensea.svg';
import Pause from '../components/img/btn-pause.svg';
import Play from '../components/img/btn-play.svg';
import RisingStars from '../components/img/icon-rising-stars.svg';
import RoundCArrowLeft from '../components/img/round-carousel-arrow-left.svg';
import RoundCArrowRight from '../components/img/round-carousel-arrow-right.svg';
import Star from '../components/img/icon-doodle-star.svg';
import Twitter from '../components/img/icon-twitter.svg'
import TwitterB from '../components/img/icon-twitter-blue.svg'
import Wallet from '../components/img/icon-wallet-white.svg';

export const IMG = ({ src, className, style, onClick }: { src: string, className?: string, style?: React.CSSProperties, onClick?: any }) => {
    let img = Info;
    const List: { [k: string]: string } = {
        AppleStore,
        ArrowDown,
        ArrowRight,
        ArrowRightBa,
        ArrowUp,
        ArrowUpB,
        Clock,
        Close,
        CloseOrange,
        Discord,
        DiscordB,
        ExclamationBlack,
        FANSI,
        FB,
        FBWhite,
        Google,
        GooglePlay,
        IG,
        Info,
        InfoWhite,
        InfoBlue,
        Linkedin,
        LinkTree,
        LinkTreeB,
        Logout,
        Logo,
        LogoFull,
        MM,
        Menu,
        Member,
        MetaBoom,
        OpenSea,
        Pause,
        Play,
        RisingStars,
        RoundCArrowLeft,
        RoundCArrowRight,
        Star,
        Twitter,
        TwitterB,
        Wallet,

    }
    if (List[src]) img = List[src];
    return <img className={className ? className : ''} src={img} onClick={onClick ? onClick : null} style={style ? style : {}} alt={src} />
}