import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import pipe from 'ramda/es/pipe';
import { toast, ToastContent } from 'react-toastify';
import __ from './gettext';
import ReactSpinkit, { SpinnerProps } from 'react-spinkit';
import Cropper from 'react-easy-crop'
import getCroppedImg from './cropImage/canvasUtlis'


export const Spinner = ({ name, color, className, style }: { name?: SpinnerProps['name'], color?: string, className?: string, style?: React.CSSProperties }) => {
    return <ReactSpinkit name={name ? name : 'ball-beat'} color={color ? color : 'black'}
        className={className ? className : ''} fadeIn='none' style={style ? style : {}} />;
};

export const Loading = () => {
    return (
        <h1 className='container section h-screen flex-grow text-center mt-4'>
            Loading...
            <Spinner className='mx-auto' />
        </h1>
    )
};

export const error = pipe(
    (err: Error | ToastContent) => {
        return err instanceof Error ? err.message : err;
    },
    (msg: ToastContent) => {
        // Strip "graphql error" string if present
        if (typeof msg === 'string') msg = msg.replace(/^GraphQL error:\s+?/i, '');
        return msg;
    },
    (msg: ToastContent) =>
        toast(msg, {
            className: 'alert bg-red-error',
            bodyClassName: 'alert-body',
            progressClassName: 'invisible',
        }),
);

export const success = pipe(__, (msg: ToastContent) =>
    toast(msg, {
        className: 'alert bg-green-success',
        bodyClassName: 'alert-body',
        progressClassName: 'invisible'
    }),
);

interface SEOProps {
    title: string;
    url?: string;
    description?: string;
    children: any;
}

interface BtnProps {
    appearance?: 'default' | 'blue' | 'black' | 'rainbow';
    disabled?: boolean;
    className?: string;
    shadow?: boolean;
    [key: string]: any;
}

export const Button = ({
    appearance = 'default',
    disabled = false,
    shadow = false,
    className,
    ...props
}: BtnProps) => {
    const cs = 'focus:outline-none btn' +
        (shadow ? 'btn-shadow' : '') +
        (disabled ? 'btn-disabled' : '') +
        (appearance === 'blue' ? 'btn-blue' : '') +
        (appearance === 'black' ? 'btn-black' : '') +
        (appearance === 'rainbow' ? 'btn-rainbow' : '');
    return <button className={cs + className} {...props} />;
};

export const SEO = (props: SEOProps) => {
    const { title, description, url, children } = props;
    return (
        <Helmet title={title} >
            <meta property='og:title' content={title} />
            {url ? <meta property='og:url' content={url} /> : ''}
            {description && [
                <meta key='name' name='description' content={description} />,
                <meta key='prop' property='og:description' content={description} />,
            ]}
            {children}
        </Helmet>
    );
};

export const ScrollToTopOnce = () => {
    useEffect(() => { window.scrollTo(0, 0); }, []);
    return null;
};

export function GetDefaultAvatar(id?: number) {
    if (!id) id = 0;
    return 'https://fansi-static.s3.ap-southeast-1.amazonaws.com/MetaBoom/img/avatar/1.png'
}

// interface CDTimerProps {
//     targetTime: number;
//     setDay: React.Dispatch<React.SetStateAction<string>>;
//     setHour: React.Dispatch<React.SetStateAction<string>>;
//     setMin: React.Dispatch<React.SetStateAction<string>>;
//     setSec: React.Dispatch<React.SetStateAction<string>>;
//     hideDay?: boolean;
//     reset?: any;
//   }

// export const CountDownTimer = ({ targetTime, setDay, setHour, setMin, setSec, hideDay, reset }: CDTimerProps) => {
//     useEffect(() => {
//       function covertTime(time: number) {
//         return time < 10 ? '0' + time : '' + time;
//       }
//       const CD = setInterval(() => {
//         let curr = Math.round(new Date().getTime() / 1000);
//         let remainTime = targetTime - curr;
//         if (remainTime < 0) remainTime = 0;
//         let days = Math.floor(remainTime / 86400);
//         let hours = Math.floor((remainTime % 86400) / 3600);
//         let mins = Math.floor((remainTime % 3600) / 60);
//         let secs = remainTime % 60;
//         if (!hideDay) setDay(covertTime(days));
//         setHour(covertTime(hours + (hideDay ? days * 24 : 0)));
//         setMin(covertTime(mins));
//         setSec(covertTime(secs));
//         console.log('check remainTime')
//         console.log(remainTime)
//         if (remainTime < 1){            
//             if(reset)reset()
//             else clearInterval(CD)
//         };
//       }, 1000);
//       return () => clearInterval(CD);
//     });
//     return null;
//   };

export const EasyCrop = ({callback}:{callback:any}) => {
    const inputRef = useRef(null);
    const [image, setImage] = useState(null);
    const [croppedArea, setCroppedArea] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const onCropComplete = (croppedAreaPercentage:any, croppedAreaPixels:any) => {      
        setCroppedArea(croppedAreaPixels);
    };

    const onSelectFile = (event:any) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.addEventListener("load", () => {
            //@ts-ignore
            setImage(reader.result);
            });
        }
    };
  
    async function getImg(){
      let cropImg = await getCroppedImg(image, croppedArea);
      callback(cropImg)
    //   UploadFile(cropImg);
      closeCrop()
    }

    function closeCrop(){
        setImage(null)
        //inputRef.current = null;
    }

    const handleClick = (event:any) => {
        //@ts-ignore
        inputRef.current.click();
      };
  
    return (
        <div className='reltaive w-full h-full'>
            <div className=' container-buttons'>
            <input
					type='file'
					accept='image/*'
					ref={inputRef}
					onChange={onSelectFile}
					style={{ display: "none" }}
				/>
                <button onClick={handleClick} className='mt-1 w-28 rounded-[31px] border border-[#767676] h-7 px-2 font-medium text-sm text-[#767676]'>從照片上傳</button>
                </div>
     
        {image ? 
        <div className='container absolute-center'>
			<div className='container-cropper'>
                {image ?
                (
                    <>
                        <div className='cropper relative'>
                            <Cropper
                                image={image}
                                crop={crop}
                                zoom={zoom}
                                aspect={1}
                                onCropChange={setCrop}
                                onZoomChange={setZoom}
                                onCropComplete={onCropComplete}
                            />
                            <button onClick={getImg} className='horizon-center bottom-0 mt-1 w-20 rounded-[31px] border border-white h-12 px-2 font-medium text-xl text-white'>選取</button>
                        </div>
                    </>
                ) : null
                }
            </div>
                
            <div className='container-buttons text-center'>				
                
				{/* <Button
					variant='contained'
					color='primary'
					onClick={handleClick}
					style={{ marginRight: "10px" }}
				>
					Choose
				</Button> */}
				{/* <Button variant='contained' color='secondary' onClick={getImg}>
					Download
				</Button> */}
			</div>
            {/* <div>
            <button onClick={handleClick} className='mt-1 w-28 rounded-[31px] border border-[#767676] h-7 px-2 font-medium text-sm text-[#767676]'>從照片上傳</button>
            <Button variant='contained' color='secondary' onClick={getImg}>
                Save
            </Button>
            <input
                type="file"
                accept='image/*'
                onChange={onSelectFile}
                ref={inputRef}
                style={{ display: "none" }}
            />
                    

            </div> */}
        </div>:''}
        </div>
    );
  }