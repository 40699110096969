import { Md5 } from 'ts-md5';

export function email_validate(email: string) {
  var re = {
    email: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  };

  if (!re.email.test(email)) {
    return false;
  } else {
    return true;
  }
}

export const convertPassword = (password: string): string => {
  return Md5.hashStr('FANSI_' + password + '_USER_!!!').toString();
};

export function IsInAppBrowser() {
  let inAppBrowser = false;
  var ua = navigator.userAgent || navigator.vendor;
  if (ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1 || ua.indexOf('Instagram') > -1 || ua.indexOf('Line') > -1 || ua.indexOf('Meta') > -1) inAppBrowser = true;
  return inAppBrowser;
}
