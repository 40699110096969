import { useState } from 'react';
import { Link } from "react-router-dom";
import { withAuthState } from '../lib/auth';
import { Personal, Web3 } from 'web3';
import { Contract } from 'web3-eth-contract';
import { ContractAbi } from 'web3-types';

import { IMG } from '../lib/imgLib';
import __, { setLanguage, getLanguage } from '../lib/gettext';

declare global {
    interface Window {
        fixedNav: boolean;
        ethereum: any;
        personal: Personal | undefined;
        web3api: Web3 | undefined;
        contract: Contract<ContractAbi> | undefined;
    }
}

interface MenuItemProps {
    title: string,
    url: string,
    forward: number, //1 link 2 a href
    info: string,
    status: number,//0 default 1 new 2 coming soon    
    clickCall?: any, //extra call when click
}

const ExploreMusic: MenuItemProps[] = [
    {
        title: 'MetaBoom',
        forward: 2,
        url: 'https://metaboom.fansi.me',
        info: 'Best web3 music player of the people, by the people, for the people.',
        status: 0
    },
    {
        title: 'Explore',
        forward: 2,
        url: 'https://metaboom.fansi.me/explore',
        info: 'Explore and discover fresh web3 music',
        status: 0
    },
    {
        title: 'Score Farming',
        forward: 2,
        url: 'https://metaboom.fansi.me/collection/0/myFarming',
        info: 'Collect music scores and earn your eligibility for rewards.',
        status: 1
    },
    {
        title: 'Leaderboard',
        forward: 2,
        url: 'https://metaboom.fansi.me/leaderboard',
        info: 'Join the crusade and get recongnized !',
        status: 1
    },
    {
        title: 'Faith Nomad',
        forward: 2,
        url: 'https://metaboom.fansi.me/FaithNomad',
        info: 'The MetaBoom verse Event to get ERC-6551 Airdrop.',
        status: 1
    },
]

const CollectMusic: MenuItemProps[] = [
    {
        title: 'Rising Stars',
        forward: 2,
        url: __('RisingStars_URL'),
        info: 'Look out for the next superstar!',
        status: 0
    },
    {
        title: '10mins of salvation',
        forward: 2,
        url: 'https://opensea.io/collection/tenminssalvation',
        info: 'FANSI Free mint event drop',
        status: 0
    },
    {
        title: 'MetaBoom Gen-1',
        forward: 2,
        url: 'https://opensea.io/collection/metaboom-nft',
        info: 'Web3 music player NFT , curate awesome playlists. be a MetaDJ to earn !',
        status: 0
    },
    {
        title: 'MetaBoom Gen-2',
        forward: 2,
        url: __('MetaBoom_URL'),
        info: 'The next generation, more features, more powerful MetaBoom.',
        status: 2
    },
]

const LanguageSelector = () => {
    const [displaySelector, setDisplaySelector] = useState(false);
    const currentLang = getLanguage();

    const handleSetLanguage = (language: string): void => {
        setDisplaySelector(!displaySelector)
        if (language === currentLang)
            return;
        setLanguage(language);
        window.location.reload();
    };

    return (
        <div className='relative my-auto w-12 flex mr-2' >
            <div className='text-lg lg:text-base  cursor-pointer' onClick={() => handleSetLanguage(currentLang === 'en' ? 'zh' : 'en')}>
                {currentLang === 'en' ? __('Lang_Zh') : __('Lang_En')}</div>
        </div >
    );
};

const iconNF = 'https://fansi-static.s3.ap-southeast-1.amazonaws.com/MetaBoom/events/BSCxGrimes/logoNeverForget.svg';

const ProfileDropdown = () => {
    const [hidden, setHidden] = useState(true);
    const toggleHidden = (e: MouseEvent) => {
        setHidden(hidden ? false : true);
    }

    return (
        <div className='relative flex lg:hidden'>
            <div className='w-full flex ' style={{ zIndex: 1001 }}>
                <div className={'hamburger my-auto ' + (hidden ? '' : 'is-active')} onClick={(e: any) => toggleHidden(e)} >
                    <span className='line' />
                    <span className='line' />
                    <span className='line' />
                </div>
                <Link to='/' className='my-auto'>
                    <IMG src='LogoFull' className='ml-4 h-7' />
                </Link>
            </div>

            <div className={'z-999 fixed w-screen h-screen bg-black left-0 top-0 pt-12 lg:py-12' + (hidden ? ' hidden' : ' flex')} >
                <div className='w-full h-full overflow-y-scroll bg-black p-6'>
                    <p className='text-xl text-gen2blue my-2 RussoOne MetaBoom '>Explore Music</p>
                    {ExploreMusic.map((item: MenuItemProps) => {
                        return <MenuItem {...item} clickCall={toggleHidden} />
                    })}
                    <Link to='https://metaboom.fansi.me/NeverForget' className='w-full p-4 flex' >
                        <img className='w-44' src={iconNF} alt='Never Forget' />
                        <div className='ml-3 border-djPink rounded-2xl border px-2 h-5 flex'>
                            <p className='text-djPink text-xs leading-none my-auto'>New !</p></div>
                    </Link>
                    <div className='h-px my-5 w-full bg-gray-4a' />
                    <p className='text-xl mb-2 RussoOne text-gen2blue MetaBoom'>Collect Music</p>
                    {CollectMusic.map((item: MenuItemProps) => {
                        return <MenuItem {...item} clickCall={toggleHidden} />
                    })}
                    <div className='h-px w-full bg-gray-4a my-3' />
                    <Link className='pl-2 h-10 flex' to='https://metaboom.gitbook.io/metaboom-whitepaper/' target='_blank'>
                        <p className='my-auto Roboto font-bold '>White Paper</p>
                    </Link>
                    <Link className='pl-2 h-10 flex' to='https://sweet-angle-e85.notion.site/FANSI-MetaBoom-Support-Center-9279528833bd488dac5fb2d6eb3153f7' target='_blank'>
                        <p className='my-auto Roboto font-bold '>Support</p>
                    </Link>
                    <div className='h-px w-full bg-gray-4a my-3' />
                    <div className='w-full flex flex-wrap relative'>
                        <div className='w-full flex h-10 pl-2'>
                            <a className='my-auto mr-4' href={__('FANSI_Twitter')} target="_blank" rel="noreferrer noopener">
                                <IMG className='w-7 h-7' src='Twitter' />
                            </a>
                            <a className='my-auto mr-4' href={__('DC_INVITE_LINK')} target="_blank" rel="noreferrer noopener">
                                <IMG className='w-7 h-7' src='Discord' />
                            </a>
                            <a className='my-auto mr-4' href={__('FANSI_IG')} target="_blank" rel="noreferrer noopener">
                                <IMG className='w-7 h-7' src='IG' />
                            </a>
                            <a className='my-auto mr-4' href={__('FANSI_LinkTree')} target="_blank" rel="noreferrer noopener">
                                <IMG className='w-7 h-7' src='LinkTree' />
                            </a>
                            <div className='w-2/5 flex justify-end'>
                                <LanguageSelector />
                            </div>
                        </div>
                        <p className='w-full  Roboto text-xs text-center whitespace-pre' style={{ color: '#6d6d6d' }}>{'©' + new Date().getFullYear() + ' FANSI. All rights reserved.'}</p>
                    </div>
                </div>
            </div>
        </div >
    );
};

const Header = () => {

    return (
        <div className='fixed w-full top-0 z-90 h-12 lg:h-16 flex bg-black bg-opacity-60'>
            <div className='relative section-lg px-6 lg:px-12 flex' >
                <ProfileDropdown />
                <Link to='/' className='hidden lg:block my-auto mr-12'>
                    <IMG src='LogoFull' className='ml-4 lg:ml-7 h-7 lg:h-8' />
                </Link>
                <div className='hidden lg:flex'>
                    <MenuBlock title='Explore Music' items={ExploreMusic} className='my-auto mr-11' />
                    <MenuBlock title='Collect Music' items={CollectMusic} className='my-auto mr-11' />
                    <Link to='https://metaboom.gitbook.io/metaboom-whitepaper/' target='_blank' className='mr-11 my-auto h-6'>
                        <p className='Roboto text-white hover:text-gen2blue '>White Paper</p>
                    </Link>
                    <Link to='https://sweet-angle-e85.notion.site/FANSI-MetaBoom-Support-Center-9279528833bd488dac5fb2d6eb3153f7' target='_blank' className='mr-11 my-auto h-6'>
                        <p className='Roboto text-white hover:text-gen2blue '>Support</p>
                    </Link>
                    <Link to='https://metaboom.fansi.me/NeverForget' className='my-auto'>
                        <img className='w-36' src={iconNF} alt='Never Forget' />
                    </Link>
                </div>
                <div className='hidden lg:flex w-48 vertical-center right-0'>
                    <a className='mr-6' href={__('FANSI_IG')} target="_blank" rel="noreferrer noopener">
                        <IMG className='w-7 h-7' src='IG' />
                    </a>
                    <a className='mr-6' href={__('FANSI_Twitter')} target="_blank" rel="noreferrer noopener">
                        <IMG className='w-7 h-7' src='Twitter' />
                    </a>
                    <a className='mr-6' href={__('DC_INVITE_LINK')} target="_blank" rel="noreferrer noopener">
                        <IMG className='w-7 h-7' src='Discord' />
                    </a>
                    <LanguageSelector />
                </div>
            </div>
        </div>
    );
};

const MenuItem = ({ title, url, forward, info, status, clickCall }: MenuItemProps) => {
    const [hover, setHover] = useState(false);

    if (status === 2) return <div className='w-full pl-4 pt-4 lg:p-2 rounded-md mb-1 bg-black cursor-default'>
        <div className='w-full flex mb-1'>
            <p className='Roboto text-xl lg:text-base font-bold text-gray-6d'>{title}</p>
            <div className='ml-3 border-gray-cd rounded-2xl border px-2 h-5 flex'>
                <p className='text-xs text-gray-cd leading-none my-auto'>Coming Soon</p>
            </div>
        </div>
        <p className={'Roboto text-xs  text-gray-cd'}>{info}</p>
    </div>

    function extraCall(e: any) {
        if (clickCall) clickCall(e);
    }

    return (<Link target={forward === 2 ? '_blank' : ''} to={url} >
        <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={(e) => { extraCall(e) }}
            className={'w-full pl-4 pt-4 lg:p-2 rounded-md mb-1 ' + (hover ? 'bg-blue-darkest' : 'bg-black')}>
            <div className='w-full flex mb-1'>
                <p className={'Roboto font-bold text-xl lg:text-base  ' + (hover ? 'text-gen2blue' : 'text-white')}>{title}</p>
                {status === 1 ? <div className='ml-3 border-djPink rounded-2xl border px-2 h-5 flex'>
                    <p className='text-djPink text-xs leading-none my-auto'>New !</p></div> : ''}
            </div>
            <p className={'Roboto text-xs ' + (hover ? 'text-gen2blue' : 'text-gray-cd')}>{info}</p>
        </div>
    </Link >)
}

const MenuBlock = ({ title, items, className }: { title: string, items: MenuItemProps[], className?: string }) => {
    const [showMenu, setMenu] = useState(false);

    return (
        <div className={(className ? className : '') + ' flex relative h-6 cursor-pointer ' + (showMenu ? 'text-gen2blue' : 'text-white')}
            onMouseEnter={() => setMenu(true)} onMouseLeave={() => setMenu(false)}>
            <p className='Roboto mr-1 my-auto'>{title}</p>
            <IMG src={showMenu ? 'ArrowUpB' : 'ArrowDown'} className='w-4 h-4 my-auto' />
            {showMenu ? <div className='absolute left-0 pt-14 w-72 '>
                <div className='w-full rounded-md border border-gen2blue bg-black p-4'>
                    {items.map((item: MenuItemProps) => {
                        return <MenuItem {...item} />
                    })}
                </div>
            </div> : ''}
        </div>
    )
}

export default withAuthState(Header);